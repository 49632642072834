html {
    overflow-x: hidden;
    scroll-behavior: smooth;
}

p {
    font-size: 16px;
}

input, textarea, button {font-family: "Montserrat"}

